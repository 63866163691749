import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import moment from 'moment';
import './TimelineComponent.css';

const boxVariant = {
  visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 0, x: 200 },
};

const TimelineItem = ({ date, title, description, image_url }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  const [timeDifference, setTimeDifference] = useState('');

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  useEffect(() => {
    const calculateTimeDifference = () => {
      const now = moment();
      const cardDate = moment(date);
      const diff = moment.duration(now.diff(cardDate));
      const years = diff.years();
      const months = diff.months();

      setTimeDifference(`${years}y ${months}m ago`);
    };

    calculateTimeDifference();
  }, [date]);

  return (
    <motion.div
      ref={ref}
      className="timeline-item"
      initial="hidden"
      animate={controls}
      variants={boxVariant}
    >
      <div className="timeline-container">
        <div className="card mb-3" style={{ maxWidth: '540px' }}>
          <div className="row g-0">
            <div className="col-md-4">
              {image_url && <img src={image_url} alt={title} className="img-fluid rounded-start" />}
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <div>{date}</div>
                <h5 className="card-title">{title}</h5>
                <p>{description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="time-difference pb-4">{timeDifference}</div>
      </div>
      
    </motion.div>
  );
};

const TimelineComponent = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://trafico-decade-api.up.railway.app/api/events/') // Replace with your actual API endpoint
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Debugging line
        const sortedEvents = [...data].sort((a, b) => moment(a.date) - moment(b.date));
        setEvents(sortedEvents);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading events: {error.message}</div>;
  }

  return (
    <div className="container-fluid p-0 m-0" style={{ backgroundColor: "#242620", zIndex: "auto" }}>
      <div className="timeline">
        {events.map((event, index) => (
          <TimelineItem
            key={index}
            date={event.date}
            title={event.title}
            description={event.description}
            image_url={event.image_url}
          />
        ))}
      </div>
    </div>
  );
};

export default TimelineComponent;